.ion-button-plain {
  --border: 0px;
  --box-shadow: none !important;
  --padding-start: 8px !important;
  --padding-end: 8px !important;
  background-color: whitesmoke !important;

  span {
    border-bottom: 2px solid #CC0000;
  }

  &:hover {
    color: #CC0000
  }
}



//Start Progress Tracker 


.progress-tracker-wrapper {
  padding-top: 5px;
  background: var(--ion-color-white);
  padding-bottom: 2px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.progress {
  position: relative;
  display: flex;
  margin: 15px 0;
  background-color: var(--ion-color-white);
}

.progress-track {
  position: absolute;
  top: 9px;
  width: 100%;
  height: 1px;
  background-color: var(--ion-color-grey-shade);
  z-index: 0;
}

// Each Step on the Progress Bar
.progress-step {
  position: relative;
  width: 100%;
  font-size: 13px;
  text-align: center;

  // Hide the final step's progress bar
  &:last-child:after {
    display: none;
  }

  // Step's circle in default state
  &:before {
    display: flex;
    margin: 0 auto;
    margin-bottom: 10px;
    width: 12px;
    height: 12px;
    background: var(--ion-color-white);
    border: 4px solid var(--ion-color-grey-shade);
    border-radius: 100%;
    content: "";
  }

  // Step's progress bar in default state
  &:after {
    content: "";
    position: absolute;
    top: 9px;
    left: 50%;
    width: 0%;
    transition: width 1s ease-in;
    height: 1px;
    background: var(--ion-color-grey-shade);
    z-index: 0;
  }

  // Step's active state
  &.is-active {
    color: var(--ion-color-primary);

    &:before {
      border: 4px solid var(--ion-color-primary);
      animation: pulse 2s infinite;
    }
  }

  // Step's complete state
  &.is-complete {
    color: var(--ion-color-medium-shade);

    // Step's circle in complete state
    &:before {
      font-size: 10px;
      color: var(--ion-color-primary);
      background: var(--ion-color-primary);
      border: 4px solid transparent;
    }

    // Step's progress bar in complete state
    &:after {
      background: var(--ion-color-primary);
      animation: nextStep 1s;
      animation-fill-mode: forwards;
    }
  }
}

// Pulse animation for Step's circle in active state
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(33, 131, 221, 0.4);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(33, 131, 221, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(33, 131, 221, 0);
  }
}

// Progressing to next step animation for Step's progress bar
@keyframes nextStep {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

//End Progress Tracker



//Mini Cards

.mini-card {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  color: var(--ion-color-light-contrast);
  padding: 10px;

  .mini-card-header {
    font-size: 14px;
    font-family: var(--tcb-font-header);
    color: var(--ion-color-primary);
    padding: 10px;
    text-align: center;
    border-left: 2px solid var(--ion-color-danger);
  }

  .mini-card-content {
    padding: 10px;
    border-left: 2px solid var(--ion-color-primary);

    ul {
      padding: 10px;
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    table {
      width: 100%;
    }
  }
}

//End Mini Card



//Application Page Common Css

.hr {
  border-bottom: 2px solid #BDBDBD;
}

.container-hr {
  border-bottom: 2px dashed #D3D3D3;
  width: calc(100% - 20px);
  margin: 20px auto;
}


.header {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
}


.page-level-actions {
  align-items: center;
  background-color: white;
  bottom: 0;
  z-index: 100;
  margin: 0 !important;

}

.page-level-actions {
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 !important;

  .actions,
  .prev {
    display: flex;
    align-items: center;
  }

  .prev-page-button{
    margin-top: 15px;
    @media screen and (max-device-width: 576px) {
      margin-top: -5px;
    }
  }



  .actions {
    gap: 20px;
  }

  .prev {
    gap: 10px;
    cursor: pointer;

    ion-icon {
      font-size: 22px;
    }
  }

  button {
    background-color: transparent;
    padding: 16px 48px;
    font-weight: 700;
    font-size: 16px;
    height: 100%;
    border: 2px solid #000033;

    &[type="link"] {
      border: none;
      padding: 8px 0;
      transition-duration: .25s;

      &.btn-cancel {
        margin-left: 15px;
        border-bottom: 2px solid #CC0000;
      }


      &.btn-cancel {
        border-bottom: 2px solid #CC0000;
      }

      &.btn-negative {
        border-bottom: 2px solid #CC0000;
      }

      &:hover {
        color: #CC0000
      }
    }
  }

  .btn-save-close {
    color: #000033;
    border: 1px solid;
  }

  .btn-save-continue {
    background: #000033;
    color: #ffffff;
  }

}

.position-sticky {
  position: sticky !important;
  transition: padding 300ms ease;
  padding-left: 40px !important;
  padding-right: 40px !important;
  box-shadow: 0 -5px 5px -5px #333;
  width: 109%;
  margin-left: -60px !important;
  @media screen and (min-width: 1024px) and (max-device-width: 1340px) {
    width: 111%;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    width: 116%;
  }

  @media screen and (min-device-width: 480px) and (max-device-width: 768px) {
    width: 124%;
  }
  
  @media screen and (min-device-width: 430px) and (max-device-width: 480px) {
    width: 127%;
  }

  @media screen and (min-device-width: 375px) and (max-device-width: 430px) {
    width: 131%;
  }

  @media screen and (max-device-width: 375px) {
    width: 134%;
  }
}

.light-button {
  --padding-bottom: 22.5px;
  --padding-top: 22.5px;
}


.page-level-actions-2 {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
  border-top: 2px solid #cecece;

  background-color: white;
  bottom: 0;
  z-index: 100;
  margin: 0 !important;
  padding: 20px 0 !important;
}

.container-info {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;
}


.radio-desc {
  margin-top: 10px;
}

.container-section-info {
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.identity-save-and-close {
  display: flex;
  float: right;
  margin-right: 60px;
  background: #000033;
  color: #ffffff;
  padding: 16px 48px;
  font-weight: 700;
  font-size: 16px;
  height: 100%;
  border: 2px solid #000033;
}

mat-dialog-container {
  position: fixed;
  top: 25%;
  left: 20%;
  background: #ffffff;
  width: 750px !important;
  height: 600px !important;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  overflow: visible !important;
}

mat-dialog-content {
  overflow: visible !important;
}



.step-info {
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  text-align: right;
  text-transform: uppercase;
  font-style: normal;
  margin-bottom: -5px;
  font-weight: bold;
}



.error-container {
  color: var(--ion-color-danger);
  font-size: 12px;
  margin-top: -6px;
}

.error-container-selection {
  color: var(--ion-color-danger);
  font-size: 12px;
  margin-top: -2px;
}

input.text-input.ng-invalid.ng-touched {
  border-bottom-color: var(--ion-color-danger)
}
