/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "base/core.scss";
@import "~@ng-select/ng-select/themes/ant.design.theme.css";

/* Basic CSS for apps built with Ionic */
@import "base/normalize.scss";
@import "base/structure.scss";
@import "base/typography.scss";
@import 'base/display.scss';

/* Optional CSS utils that can be commented out */
@import "base/padding.scss";
@import "base/float-elements.scss";
@import "base/text-alignment.scss";
@import "base/text-transformation.scss";
@import "base/flex-utils.scss";
@import "base/views.scss";
@import "base/common.scss";

/* CSS for ngx data table */
@import '@swimlane/ngx-datatable/index.css';
@import '@swimlane/ngx-datatable/themes/material.scss';
@import '@swimlane/ngx-datatable/assets/icons.css';


/* ngselect */
//@import "~@ng-select/ng-select/themes/material.theme.css";
@import "@ng-select/ng-select/themes/default.theme.css";
.no-scroll {
    --overflow: hidden;
}

.ngx-datatable.material {
    box-shadow: none;
}

.sc-ion-label-md-h{
    white-space: normal !important;
}

.d-block {
    display: block;
}


@keyframes slide {
	20% {
		opacity: 0;
	}
	60% {
		transform: translateX(0%);
	}
	75% {
		transform: translateX(0%);
	}
	100% {
		opacity: 1;
		transform: translateX(0%);
	}
}



