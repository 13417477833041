html {
  font-family: var(--ion-font-family);
}

a {
  background-color: transparent;
  color: var(--ion-color-primary, #191947);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--tcb-font-header);
  margin-top: 16px;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.18px;
  font-weight: 700;
  text-transform: uppercase;
}
h1 {
  margin-top: 20px;
  font-size: 26px;
}
h2 {
  margin-top: 18px;
  font-size: 24px;
}
h3 {
  font-size: 22px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

small {
  font-size: 75%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}


.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 600;
}


.font-italic {
  font-style: italic;
}


/*# sourceMappingURL=typography.css.map */
