  /////// Navigation Split Pane Global Start  //////////

.split-pane-visible>.split-pane-side {
    min-width: 250px !important;
    max-width: 250px !important;
  }

  ion-buttons {
    color: white;
  }

  .menu-header-bg {
    height: 180px;
    width: 350px;
    background-color: #191947;
    background: linear-gradient(90deg, #191947 0%, #4D71D7 100%);
    box-shadow: 0px 1px 10px rgba(98, 140, 255, 0.5);
    transform: rotate(-15deg);
    border-radius: 10px 10px 10px 50px;
    margin-left: -18px;
    margin-top: -50px;
    margin-bottom: 60px;
  }


  .header-content {
    position: absolute;
    top: 30px;
    left: 15px;
    display: flex;
    align-items: center;

    ngx-avatar {
      border: 7px solid #5e7ccc;
    }
  }

  .header-content-username {
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
  }

  .welcome-msg {
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
  }

  .menu-items {
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;

    ion-icon {
      margin-right: 20px;
      --color: var(--ion-color-white);
    }

    ion-item {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 5px;
    }
  }

  .top-navbar{
    background: var(--ion-color-primary);
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
  }
  .logo-topnav {
    margin-top: 15px;
  }

  .container-pre {
    --background: var(--ion-color-primary);
    --padding-bottom: 20px;

    .child-container {
      background-color: var(--ion-color-white);
      border-radius: 0px;
      padding: 20px;
    }
  }

  .ion-margin-start {
    margin-left: 20px;
  }

  ion-split-pane {
    --side-max-width: 18%;
    --side-min-width: 300px;
  }

  ion-menu {
    --background: var(--ion-color-white);
    --color: var(--ion-color-white);
    ion-content {
      --background: var(--ion-color-white);

        .active-link {
          --background: var(--ion-color-primary) !important;
          --color: #FFFFFF !important;
          ion-icon{color: var(--ion-color-danger); }
          border-radius: 5px;
        }

      ion-item {
        --background: var(--ion-color-white);
        --color: var(--ion-color-primary);
      }

      ion-list {
        --background: var(--ion-color-white);
        --color: var(--ion-color-primary);
      }
    }
  }



  .list-md {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .alert-icon {
    font-size: 25px;
  }

  /////// Navigation Split Pane Global End  //////////




  ion-card-title {
    font-family: var(--tcb-font-header);
    font-size: 18px;
  }

  ion-card-subtitle {
    font-family: var(--tcb-font-header);
  }

  ion-button {
    --font-family: var(--tcb-font-header);
    --padding-top: 24px;
    --padding-bottom: 24px;
    --padding-start: 48px;
    --padding-end: 48px;
    --transition: box-shadow 280mscubic-bezier(0.4, 0, 0.2, 1), background-color 15ms linear, color 15ms linear;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.06em;
    --border-radius: 0px !important;
}

.ion-radio-group {
  display: flex;

  ion-item {
    --border-color: transparent;

    ion-radio {
      --color-checked: #191947;
    }
  }
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

ion-popover {
 margin-left: -90px;
}

ion-card{
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

ion-card-header {
  border-left: 4px solid var(--ion-color-danger);
  -webkit-box-shadow:0px 1px 1px ;
  -moz-box-shadow:0px 1px 1px var(--ion-color-white);
  box-shadow:0px 1px 1px rgb(217, 217, 217);
}

ion-card-content {
  padding: 15px;
  margin-top: 10px;
  color: var(--ion-color-light-contrast);
}

.ion-input-custom {
  --background: var(--ion-color-white-shade);
    margin-bottom: 10px;
    width: 450px;
    max-width: 100%;
    @media only screen and (max-width : 500px) {
      width: 100%;
     }
    // @media screen and (min-width: 1024px) and (max-device-width: 1340px) {
    //   max-width: 500px;
    // }

    // @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    //   max-width: 600px;
    // }

    // @media screen and (min-device-width: 480px) and (max-device-width: 768px) {
    //   width: 124%;
    // }

    // @media screen and (max-device-width: 480px) {
    //   width: 136%;
    // }
}


.ion-input-custom-no-width {
  --background: var(--ion-color-white-shade);
    margin-bottom: 10px;
    width: 100%;
}

.ion-input-custom-textarea {
  --background: var(--ion-color-white-shade);
    margin-bottom: 10px;
    width: 450px;
    @media only screen and (max-width : 500px) {
      width: 100%;
     }
}


//Floating Label CSS


.has-float-label {
  display: block;
  position: relative;
}

// .has-float-label input:hover:not(:disabled):not(:focus) {
// 	border-bottom:2px solid #1e1e1e !important;
// }
// .has-float-label input[value=""] {
// 	border-top:2px solid #1e1e1e !important;
// }

.has-float-label input:hover:invalid:not(:placeholder-shown):not(:focus) {
	border-bottom:2px solid #ff1744 !important;
}

.has-float-label input:hover:valid:not(:placeholder-shown):focus {
	border-bottom:2px solid #005a24 !important;
}

.has-float-label input:invalid:not(:focus) {
	border-bottom:2px solid #ff1744 !important;
}


.has-float-label input:disabled {
	border-bottom:2px dotted #8a8a8a !important;
	pointer-events: none;
	background-color:inherit;
}


.has-float-label input {
  font-size: inherit;
  padding: 27px 0px 4px 0px;
  border: 0;
  border-radius: 0;
  box-shadow:none;
  background-color:initial;
  color:#000000;
  caret-color:#000033;
  margin-bottom: 10px;
  background: var(--ion-color-white-shade);
  border-bottom:1px solid rgb(191, 189, 189);
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 30px;
  width: 450px;
  max-width: 100%;
  @media only screen and (max-width : 500px) {
    width: 100%;
   }
  cursor: auto;
}


input {
  font-family: sans-serif;
  font-size: 14px;
  line-height: 1.15;
  margin: 0;
  outline: none;
}


.has-float-label input:placeholder-shown:not(:focus) + .label {
  font-size: 16px;
  padding-left: 15px;
  top: 8px;
  color:#000000;
}

.has-float-label label, .has-float-label > .label {
  position: absolute;
  left: 0;
  top: 0;
  cursor: text;
  font-size: 12px;
  padding-top: 12px;
  color:#000000;
  transition: all .2s;
  padding-left: 15px;
  pointer-events:none;
}

.has-float-label input:invalid ~ .label {
  color:#ff1744;
  padding-left: 15px;
}

.has-float-label:invalid {
  color:#ff1744;
  padding-left: 15px;
  border-bottom:1px solid #ff1744 !important;
}

.has-float-label input:focus ~ .label {
  padding-left: 15px;
  color:#000033;
}

.has-float-label input:invalid:not(:focus):not(:placeholder-shown) ~ .error {
display:block;
}
.error {
color:#ff1744;
display:none;
}

.has-float-label input:invalid:not(:focus):not(:placeholder-shown) ~ .helper {
display:none;
}
.helper {
display:block;
color:#6d6d6d;

}

.helper, .error {
font-size:12px;
padding-top:8px;
line-height:1;
}

// .ng-select .ng-arrow-wrapper {
//   width: 16px;
//   height: 26px;
//   transform: rotate(224deg);
// }

// .ng-select.ng-select-opened>.ng-select-container .ng-arrow-wrapper {
//   transform: rotate(46deg);
//   opacity: 1;
// }


// .ng-select .ng-select-container .ng-value-container {
//   border: 0 !important;
//   min-height: 40px;
// }


// .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-clear-wrapper, .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper {
//   border-top: 0;
// }

// .ng-select .ng-select-container .ng-value-container .ng-input>input {
//   margin-top: 20px;
//   margin-left: 10px;
// }


.ng-select .ng-clear-wrapper {
    opacity: 0;
    color: #a6a6a6;
    background: rgba(0,0,0,0.25);
    width: 15px;
    height: 16px;
    text-align: center;
    border-radius: 12px;
    vertical-align: middle;
    transition: color .3s ease;
    position: absolute;
    right: 13px;
}

.ng-select .ng-select-container {
  color: rgba(0,0,0,0.65);
  background-color: var(--ion-color-white-shade);
  border-radius: 0px;
  border: 0;
  /* border: 1px solid #d9d9d9; */
  min-height: 30px;
  align-items: center;
  transition: all 0.3s;
  box-sizing: border-box;
  padding: 10px;
  border-bottom: 1px solid #c3c0c0;
}

.ng-select.ng-select-opened>.ng-select-container {
  background-color: var(--ion-color-white-shade);
  border-radius: 0px;
  box-shadow: 0 0 0 2px #ffffff;
}

// .ng-select.ng-select-opened>.ng-select-container {
//     background: var(--ion-color-white-shade);
//     margin-bottom: 0;
//     width: 100%;
//     border-color: #ffffff;
//     box-shadow: none;
// }

// .ng-select .ng-select-container .ng-value-container {
//   align-items: stretch;
//   padding: 10px;
//   border-top: 0.84375em solid transparent;
// }

// .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container {
//   padding: 10px;
// }

// .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
//   background-color: var(--ion-color-primary);
//   border-radius: 2px;
//   color: var(--ion-color-white-shade) !important;
//   padding: 2px 5px;
//   margin: 0 0.4375em 0.4375em 0;
//   line-height: 17px;
// }

.ng-placeholder {
  display: none;
}

.background {
  .item-native {
    background-color: rgba(0,0,0,0.5);
  }

}
.w-100 {
  width: 100%;
}


.display-left {
  display: flex !important;
  justify-content: left !important;
}

ion-popover {
  margin-left: 0px !important;
}

.disabled {
  pointer-events: none;
  opacity: .5;
}

ng-select.ng-select-custom .ng-select-container {
  min-height: 53px;
  border-bottom: 1px solid #000033;
  border-radius: 0px;
  width: 85%;
  background: #F5F5F5 !important;
}
ng-select .ng-select-custom.ng-select-single
.ng-select-container
.ng-value-container
.ng-input

ng-select.ng-select-custom.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  top: 10px;
}
ng-select.ng-select-custom
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option
  .row {
  margin: 0;
  padding: 5px;
}

ng-select.ng-select-custom.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  font-size: 1rem;
  background-color: #FFFFFF;
  border-radius: 4px;
  color: black;
}
ng-select.ng-select-custom.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon.left {
  border-right: 1px solid #fff;
}
ng-select.ng-select-custom.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon:hover {
  background-color: #FFFFFF;
}
ng-select.ng-select.ng-select-focused:not(.ng-select-opened)
  .ng-select-container {
  border-color: #000033;
}

ng-dropdown-panel {
  width: 85% !important;
}

ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: black !important;
  font-size: 16px !important;
  padding: 10px !important;
}

ng-select .ng-select-container .ng-value-container .ng-value {
  color: black !important;
  font-size: 16px !important;
}

ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: black !important;
  font-size: 16px;
}

.label {
  color: #585858;
  font-weight: 500;
  font-size: 1.1rem;
}
.form-control-custom {
  border: none;
  border-bottom: 1px solid #000033;
  padding: 16px 20px !important;
  width: 85%;
  background: #F5F5F5 !important;
}
.mat-placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  background-color: none;
  opacity: none;
  padding: 7px 0px 0px 10px;
}

.right {
  display: flex;
  float: right;
}
ion-select {
  div .select-text .select-placeholder {
     color: black !important;
     opacity: 1 !important;
  }
}

textarea:focus, input:focus{
  outline: none;
}

.address-autocomplete {
  border-bottom: 1px solid grey;
  padding: 20px !important;
  background-color: rgb(245, 245, 245);
}

.address-autocomplete-dynamic {
  border-bottom: 1px solid grey;
  padding: 20px !important;
  background-color: rgb(245, 245, 245);
  width: 39%;
}

::placeholder {
  opacity: 1 !important;
  color: black !important;
}

ion-select::part(placeholder) {
  color: black;
  opacity: 1;
}

.idenitity-header {
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
  margin-bottom: 5px;
  font-family: 'Roboto';
}

.no-ident {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  padding: 40px;
}

.upload-box-mr {
  margin-right: 7rem;
}

.mat-snack-bar-container.custom-toast-success {
  background-color: #76C7A5;
  font-family: 'Roboto';
}
.mat-snack-bar-container.custom-toast-error {
  background-color: #EC6A5C;
  font-family: 'Roboto';
}

.identity-modal {
  position: fixed;
  top: 25%;
  left: 25%;
  width: 750px !important;
  height: 700px !important;
}

.questionsForIdentity {
  padding: 10px;
  font-family: 'Roboto';
}



