.ion-text-uppercase {
  /* stylelint-disable-next-line declaration-no-important */
  text-transform: uppercase !important;
}

.ion-text-lowercase {
  /* stylelint-disable-next-line declaration-no-important */
  text-transform: lowercase !important;
}

.ion-text-capitalize {
  /* stylelint-disable-next-line declaration-no-important */
  text-transform: capitalize !important;
}

@media (min-width: 576px) {
  .ion-text-sm-uppercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: uppercase !important;
  }

  .ion-text-sm-lowercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: lowercase !important;
  }

  .ion-text-sm-capitalize {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: capitalize !important;
  }
}
@media (min-width: 768px) {
  .ion-text-md-uppercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: uppercase !important;
  }

  .ion-text-md-lowercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: lowercase !important;
  }

  .ion-text-md-capitalize {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: capitalize !important;
  }
}
@media (min-width: 992px) {
  .ion-text-lg-uppercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: uppercase !important;
  }

  .ion-text-lg-lowercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: lowercase !important;
  }

  .ion-text-lg-capitalize {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: capitalize !important;
  }
}
@media (min-width: 1200px) {
  .ion-text-xl-uppercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: uppercase !important;
  }

  .ion-text-xl-lowercase {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: lowercase !important;
  }

  .ion-text-xl-capitalize {
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: capitalize !important;
  }
}

/*# sourceMappingURL=text-transformation.css.map */
